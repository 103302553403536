import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, ElementRef,
    OnInit,
    PLATFORM_ID,
    Renderer2,
    ViewChild,
} from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {
    AccountsService,
    AlertService,
    AuthService, CommunicationService,
    FeatureProjectService, HelperService,
    ProductsService,
    SubscribersService
} from '@coreservices';

import {ThemeOptions} from 'src/app/core/theme-options';
import {PaginationModel, ProductModel, RequestParamModel, TradeTypeModel} from "@coremodels";
import {HttpErrorResponse} from "@angular/common/http";
import {CommonConstants as Constants} from 'src/app/core/constants/common-constants';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DOCUMENT, isPlatformBrowser, isPlatformServer} from '@angular/common';
import {Inject} from '@angular/core';

import SwiperCore, {
    Navigation,
    Pagination,
    Autoplay,
    EffectCoverflow
} from "swiper";
import {CarrierJobThankYouModalComponent} from './modal/carrier-job-thank-you-modal/carrier-job-thank-you-modal.component';
import {CarrierJobDeclineModalComponent} from './modal/carrier-job-decline-modal/carrier-job-decline-modal.component';
import {environment} from 'src/environments/environment.stage';

// install Swiper components
SwiperCore.use([
    Navigation,
    Pagination,
    Autoplay,
    EffectCoverflow
]);

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    // encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, AfterViewInit {
    offsetFlag = false;
    @ViewChild('allInOneImageBox', {read: ElementRef}) allInOneImageBox?: ElementRef;
    @ViewChild('swiperSlideinnerbox', {read: ElementRef}) swiperSlideinnerbox?: ElementRef;
    @ViewChild('featureProductColumnBox', {read: ElementRef}) featureProductColumnBox?: ElementRef;
    @ViewChild('serviceListImageBox', {read: ElementRef}) serviceListImageBox?: ElementRef;
    @ViewChild('featureProjectSwiperBox', {read: ElementRef}) featureProjectSwiperBox?: ElementRef;

    customOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: true,
        autoplay: true,
        navSpeed: 3000,
        responsive: {
            0: {
                items: 1
            },
        },
    }

    public screenWidth: any;
    public screenHeight: any;
    public urlArray: any;
    allInOneProduct!: ProductModel;
    featureProduct!: ProductModel[];
    featureProject: any = [];
    allInOneProductUrl!: string;
    allInOneProductImage!: string;
    allInOneProductFinishes!: string;
    s3BucketImageURL!: any;
    s3BuketFinishURL!: any;
    s3BuketFinishFolder!: any;
    exampleConfig = {
        loop: true,
        effect: 'coverflow',
        spaceBetween: 100,
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 1.9,
        pagination: {
            el: '.product-pagination',
            clickable: true,
        },
        autoplay: true,
        navigation: {prevEl: '#product-left', nextEl: '#product-right'},
        breakpoints: {
            '300': {slidesPerView: 1.5, spaceBetween: 60},
            '600': {slidesPerView: 1.5, spaceBetween: 80},
            '768': {slidesPerView: 1.6, spaceBetween: 80},
            '991': {slidesPerView: 1.6, spaceBetween: 100},
            '1024': {slidesPerView: 1.7, spaceBetween: 100},
            '1300': {slidesPerView: 1.8, spaceBetween: 120},
            '1500': {slidesPerView: 2.0, spaceBetween: 140},
            '1600': {slidesPerView: 2.0, spaceBetween: 150},
            '2000': {slidesPerView: 2.0, spaceBetween: 150},
            '2400': {slidesPerView: 2.0, spaceBetween: 200},
            '2800': {slidesPerView: 2.0, spaceBetween: 250},
            '3200': {slidesPerView: 2.0, spaceBetween: 300},
            '3600': {slidesPerView: 2.0, spaceBetween: 350},
            '4000': {slidesPerView: 2.0, spaceBetween: 400}
        },
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 250,
            modifier: 1,
            slideShadows: true,
        }
    };
    Constants = Constants;
    isUserAuthenticated: boolean = false;
    isTradeUser: boolean = false;
    lookingForMoreModelRef: any;
    rightDrawerSubScribe: any;
    tradeType!: TradeTypeModel[];
    errorImage: any;
    isLoading!: boolean;
    isBrowser: any;
    isServer: any;
    allInOneImageWidth: any;
    allInOneImageHeight: any;
    swiperSlideWidth: any;
    swiperSlideHeight: any;
    FeatureProductWidth: any;
    FeatureProductHeight: any;
    serviceListImageWidth: any;
    serviceListImageHeight: any;
    featureProjectSwiperImageWidth: any;
    featureProjectSwiperImageHeight: any;
    productionUrl: string = 'https://gatherco.com.au'

    constructor(
        private titleService: Title,
        private globals: ThemeOptions,
        private subscriberService: SubscribersService,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private alertService: AlertService,
        private productsService: ProductsService,
        public featureProjectService: FeatureProjectService,
        public modelService: NgbModal,
        public cd: ChangeDetectorRef,
        private accountServices: AccountsService,
        private changeDetectionRef: ChangeDetectorRef,
        public helperService: HelperService,
        private meta: Meta,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        @Inject(PLATFORM_ID) private platformId: any,
        private communicationService: CommunicationService,
    ) {
        this.allInOneProductUrl = '';
        this.s3BucketImageURL = '';
        this.s3BuketFinishURL = '';
        this.lookingForMoreModelRef = this.subscriberService.isUserLoggedIn.subscribe((options) => {
            this.isUserAuthenticated = options;
            if (options) {
                const userModal = this.authService.getUserProfile();
                this.isTradeUser = userModal?.role === Constants.USER_TYPE_TRADE;
            } else {
            }
        });
        this.subscriberService.isUserLoggedIn.closed
        this.setMetaTags();
        this.setCanonicalURL();

        this.isBrowser = isPlatformBrowser(platformId);
        this.isServer = isPlatformServer(platformId);
    }

    setMetaTags() {
        // if(environment.includeRobotsMeta){
        //   this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
        // }
        // if(this.productionUrl == this.document.location.origin){
        //   this.addFacebookPixelScript();
        // }
        this.meta.updateTag({
            name: 'description',
            content: 'GatherCo curate only the best bespoke indoor & outdoor natural stone pavers and tiles. Working only with trades, our architectural surfaces will elevate any project.'
        });
        // Open Graph / Facebook
        this.meta.updateTag({
            property: 'og:url',
            content: Constants.FRONTEND_PREFIX_WITH_WWW + this.router.url,
        });
        this.meta.updateTag({
            property: 'og:title',
            content: 'Natural Stone Pavers, Tiles, Cladding & Cobblestone | GatherCo',
        });
        this.meta.updateTag({
            property: 'og:description',
            content: 'GatherCo curate only the best bespoke indoor & outdoor natural stone pavers and tiles. Working only with trades, our architectural surfaces will elevate any project.',
        });
        this.meta.updateTag({
            property: 'og:image',
            content: 'https://ik.imagekit.io/c0mm9a4xe/WEBSITE_ASSET_STAGING/home-page-banner.jpg',
        });
        // Open Graph / Facebook
        // Twitter
        this.meta.updateTag({
            property: 'twitter:url',
            content: Constants.FRONTEND_PREFIX_WITH_WWW + this.router.url,
        });
        this.meta.updateTag({
            property: 'twitter:title',
            content: 'Natural Stone Pavers, Tiles, Cladding & Cobblestone | GatherCo',
        });
        this.meta.updateTag({
            property: 'twitter:description',
            content: 'GatherCo curate only the best bespoke indoor & outdoor natural stone pavers and tiles. Working only with trades, our architectural surfaces will elevate any project.',
        });
        this.meta.updateTag({
            property: 'twitter:image',
            content: 'https://ik.imagekit.io/c0mm9a4xe/WEBSITE_ASSET_STAGING/home-page-banner.jpg',
        });
        // Twitter
        this.meta.updateTag({
            name: 'facebook-domain-verification',
            content: 'lwmk3bgszvwxa1hv5w5yrrw1uavba6',
        });
    }

    addFacebookPixelScript(): void {
        const script = this.renderer.createElement('script');
        script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '975553447276140');
      fbq('track', 'PageView');
    `;
        this.renderer.appendChild(this.document.head, script);
    }

    setCanonicalURL() {
        const head = this.document.getElementsByTagName('head')[0];
        var element: any = this.document.querySelector(`link[rel='canonical']`) || null
        if (element == null) {
            element = this.document.createElement('link') as HTMLLinkElement;
            head.appendChild(element);
        }
        element.setAttribute('rel', 'canonical')
        element.setAttribute('href', Constants.FRONTEND_PREFIX_WITH_WWW + this.router.url)
    }

    ngOnDestroy() {
        this.rightDrawerSubScribe?.unsubscribe();
        this.lookingForMoreModelRef?.unsubscribe();
        this.modelService.dismissAll()
    }

    getFileName(str: any) {
        var fileName = '';
        if (str != undefined && str !== null && str !== '') {
            var newFileName = str.split('\\').pop().split('/').pop(); // Get file name
            newFileName = newFileName.split("/").slice(-1).join().split(".").shift(); // Remove extension
            fileName = newFileName.replace(/_/g, ' '); // Replace underscore(_) with dash(-)
        }
        return fileName;
    }

    async ngOnInit() {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;

        this.isUserAuthenticated = this.authService.isUserAuthenticated();
        this.s3BucketImageURL = Constants.S3_PRODUCT_IMAGES_IMAGE_KIT_URL;
        this.s3BuketFinishURL = Constants.S3_FINISH_SPECIFICATION_IMAGE_KIT_URL;
        this.s3BuketFinishFolder = Constants.S3_FINISH_SPECIFICATION_IMAGE_KIT_FOLDER;

        if (this.authService.isUserAuthenticated()) {
            this.router.navigate(['/']);
            const userModal = this.authService.getUserProfile();
            this.isTradeUser = userModal.role === Constants.USER_TYPE_TRADE;
        } else {
            let isElse = true;
            if (localStorage.getItem('accept_require')) {
                this.globals.rightSidebar = true;
                setTimeout(() => {
                    this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
                }, 200);
                this.communicationService.emitRightSidebarClick(true);
            }
            this.urlArray = this.router.url.split('/');
            if (this.urlArray[1] != null && this.urlArray[1] != '' && this.urlArray[1] == 'login') {
                this.globals.rightSidebar = true;
                setTimeout(() => {
                    this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
                }, 200);
                this.communicationService.emitRightSidebarClick(true);
            }
        }

        if (localStorage.getItem('is_open_login_sidebar') && localStorage.getItem('is_open_login_sidebar') == 'true') {
            this.globals.rightSidebar = true;
            setTimeout(() => {
                this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
            }, 200);
            this.communicationService.emitRightSidebarClick(true);
        }

        const resetPasswordToken = this.route.snapshot.paramMap.get('token');
        const resetPasswordEmail = this.route.snapshot.paramMap.get('email');
        if (typeof resetPasswordToken != undefined && resetPasswordToken != '' && resetPasswordToken != null && typeof resetPasswordEmail != undefined && resetPasswordEmail != '' && resetPasswordEmail != null) {
            this.globals.rightSidebar = true;

            const self = this;
            setTimeout(function () {
                self.subscriberService.rightDrawerToggle(self.globals.rightSidebar);

                self.globals.resetPasswordForm = true;
                self.subscriberService.resetPasswordFormToggle(self.globals.resetPasswordForm);
            }, 500);
            this.communicationService.emitRightSidebarClick(true);
        } else {
            this.router.navigate(['/']);
        }

        const carrier_job_status = localStorage.getItem('carrier_job_status');
        if (carrier_job_status) {
            if (carrier_job_status == "A") {
                const modelRef = this.modelService.open(CarrierJobThankYouModalComponent, {centered: true});
                modelRef.result.then(value => {
                }).catch((error) => {
                }).finally(() => {
                })
            } else if (carrier_job_status == "D") {
                const modelRef = this.modelService.open(CarrierJobDeclineModalComponent, {centered: true});
                modelRef.result.then(value => {
                }).catch((error) => {
                }).finally(() => {
                })
                //this.alertService.success('Carrier job decline successfully.');
            }
            localStorage.removeItem('carrier_job_status');
        }

        const carrier_job_status_error_message = localStorage.getItem('carrier_job_status_error_message');
        if (carrier_job_status_error_message) {
            this.alertService.error(carrier_job_status_error_message);
            localStorage.removeItem('carrier_job_status_error_message');
        }


        this.titleService.setTitle('Natural Stone Pavers, Tiles, Cladding & Cobblestone | GatherCo');

        // All in one product
        const allInOneRequestModel = new RequestParamModel();
        allInOneRequestModel.addDefaultFilter('is_all_in_one', 'YES', 'number');
        allInOneRequestModel.expands = ['availableFinishes', 'formatHeroImage', 'familyHeroImage', 'productSpecificationIcons'];
        allInOneRequestModel.fields = ['type', ' id', 'url_slug'];
        allInOneRequestModel.pageSize = 1;
        await this.productsService.getAllInOneProduct(allInOneRequestModel).subscribe((dataList: PaginationModel<any>) => {
            allInOneRequestModel.update<any>(dataList);
            this.allInOneProduct = dataList.data[0] as ProductModel;
            if (dataList.data.length > 0 && this.allInOneProduct) {
                this.allInOneProductFinishes = this.allInOneProduct && this.allInOneProduct?.availableFinishes && this.allInOneProduct?.availableFinishes.length > 0 && this.allInOneProduct?.availableFinishes[0].finish_image;
                // if (this.allInOneProduct?.type == 'S') {
                //     this.allInOneProductUrl = '/surfaces/' + this.allInOneProduct?.url_slug;
                //     this.allInOneProductImage = '/' + this.allInOneProduct.formatHeroImage.path;
                // } else if (this.allInOneProduct?.type == 'D') {
                //     this.allInOneProductUrl = '/decor/' + this.allInOneProduct?.url_slug;
                //     this.allInOneProductImage = '/' + this.allInOneProduct.familyHeroImage.path;
                // }
              this.allInOneProductUrl = '/' + this.allInOneProduct?.url_slug;
              this.allInOneProductImage = '/' + this.allInOneProduct.formatHeroImage.path;
            }
        }, (error: HttpErrorResponse) => {
        });
        // All in one product

        // Feature product
        const featureProductRequestModel = new RequestParamModel();
        featureProductRequestModel.addDefaultFilter('is_feature', 'YES', 'number');
        featureProductRequestModel.sortChange('modified_at', 'desc');
        featureProductRequestModel.expands = ['formatHeroImage'];
        featureProductRequestModel.fields = ['id', 'formatImage.path', 'family_name', 'product_category_name', 'category_name', 'type', 'url_slug'];
        featureProductRequestModel.pageSize = 6;
        await this.productsService.getAllInOneProduct(featureProductRequestModel).subscribe((dataList: PaginationModel<any>) => {
            featureProductRequestModel.update<any>(dataList);
            this.featureProduct = dataList.data;

        }, (error: HttpErrorResponse) => {
        });
        // Feature product

        // Feature Project
        const featureProjectRequestModel = new RequestParamModel();
        featureProjectRequestModel.addDefaultFilter('is_display_at_home_page', 'Y', 'number');
        featureProjectRequestModel.sortChange('id', 'desc');
        featureProjectRequestModel.fields = ['id', 'name', 'image_1', 'architect_name'];
        await this.featureProjectService.getProjects(featureProjectRequestModel).subscribe((dataList: PaginationModel<any>) => {
            featureProjectRequestModel.update<any>(dataList);
            this.featureProject = dataList.data;
            setTimeout(() => {
                this.FeatureProductColumnBox();
                this.FeatureProjectSwiperBox();
            }, 1000);
        }, (error: HttpErrorResponse) => {
        });
        // Feature Project

        this.rightDrawerSubScribe = this.subscriberService.rightDrawer.subscribe((rightDrawerFlag) => {

            if (rightDrawerFlag) {
                this.modelService.dismissAll()
            }
        })
        this.getTradeTypeList();

    }


    getTradeTypeList() {
        let requestParamModel = new RequestParamModel();
        delete requestParamModel.pageSize;
        requestParamModel.fields = ['name'];
        requestParamModel.sortChange('name', 'asc');
        this.accountServices.getTradeTypeLists(requestParamModel).subscribe((dataList: PaginationModel<any>) => {
            requestParamModel.update<any>(dataList);
            this.tradeType = dataList.data;
            this.changeDetectionRef.markForCheck();
        }, (error: HttpErrorResponse) => {
            this.helperService.handleError(error);
        });
    }

    rightSidebarToggle() {
        this.globals.rightSidebar = true;
        this.globals.registrationForm = 'trade';
        setTimeout(() => {
            this.subscriberService.registrationFormToggle(this.globals.registrationForm);
            this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
        }, 200);
        this.communicationService.emitRightSidebarClick(true);
    }

    redirectTo(path: string) {
        this.router.navigate(['/' + path]);
    }

    productImage(product: any, imageSize: string) {
        let imageUrl: any;
        if ((product?.type == Constants.PRODUCT_TYPE_SURFACE || product?.type == Constants.PRODUCT_TYPE_SURFACE_ACCESSORY) && product?.formatHeroImage) {
            imageUrl = product.formatHeroImage;
            return imageUrl?.path ? Constants.S3_PRODUCT_IMAGES_IMAGE_KIT_URL + '/' + imageUrl.path + imageSize : '';
        } else if ((product?.type == Constants.PRODUCT_TYPE_DECOR || product?.type == Constants.PRODUCT_TYPE_DECOR_ACCESSORY) && product?.familyHeroImage) {
            imageUrl = product?.familyHeroImage;
            return imageUrl?.path ? Constants.S3_PRODUCT_IMAGES_IMAGE_KIT_URL + '/' + imageUrl.path + imageSize : '';
        }
        return imageUrl;
    }

    productImagePath(product: any) {
        let imageUrl: any;
        if ((product?.type == Constants.PRODUCT_TYPE_SURFACE || product?.type == Constants.PRODUCT_TYPE_SURFACE_ACCESSORY) && product?.formatHeroImage) {
            imageUrl = product.formatHeroImage;
            return imageUrl?.path ? '/' + imageUrl.path : '';
        } else if ((product?.type == Constants.PRODUCT_TYPE_DECOR || product?.type == Constants.PRODUCT_TYPE_DECOR_ACCESSORY) && product?.familyHeroImage) {
            imageUrl = product?.familyHeroImage;
            return imageUrl?.path ? '/' + imageUrl.path : '';
        }
        return imageUrl;
    }

    redirectToProduct(product: any) {
        let productUrl: any;
        // if ((product?.type == Constants.PRODUCT_TYPE_SURFACE || product?.type == Constants.PRODUCT_TYPE_SURFACE_ACCESSORY)) {
        //     productUrl = '/surfaces/' + product.url_slug;
        // } else if ((product?.type == Constants.PRODUCT_TYPE_DECOR || product?.type == Constants.PRODUCT_TYPE_DECOR_ACCESSORY)) {
        //     productUrl = '/decor/' + product.url_slug;
        // }
      productUrl = '/' + product.url_slug;
        return productUrl;
    }

    // ngAfterViewInit() {
    //   this.cd.detectChanges()
    // }

    removeHtmlTags(string: any) {
        if (string != "") {
            string = string.replace(/<[^>]*(>|$)|&nbsp;/g, '');
        }
        return string;
    }

    ngAfterViewInit() {
        this.allInOneImageBoxinner();
        this.SwiperSlideinnerbox();
        this.ServiceListImageBox();
        this.FeatureProjectSwiperBox();

    }

    allInOneImageBoxinner() {
        // Accessing the native element
        let divElement: HTMLElement = this.allInOneImageBox?.nativeElement;

        // Getting inner width and height
        this.allInOneImageWidth = divElement.clientWidth;
        this.allInOneImageHeight = divElement.clientHeight;
    }

    SwiperSlideinnerbox() {
        let divElement: HTMLElement = this.swiperSlideinnerbox?.nativeElement;
        this.swiperSlideWidth = divElement.clientWidth;
        this.swiperSlideHeight = divElement.clientHeight;
    }

    FeatureProductColumnBox() {
        this.FeatureProductWidth = 0;
        this.FeatureProductHeight = 0;
        if (this.featureProductColumnBox) {
            let divElement: HTMLElement = this.featureProductColumnBox?.nativeElement;
            this.FeatureProductWidth = divElement.clientWidth;
            this.FeatureProductHeight = divElement.clientHeight;
        }
    }

    ServiceListImageBox() {
        let divElement: HTMLElement = this.serviceListImageBox?.nativeElement;
        this.serviceListImageWidth = divElement.clientWidth;
        this.serviceListImageHeight = divElement.clientHeight;
    }

    FeatureProjectSwiperBox() {
        this.featureProjectSwiperImageWidth = 882;
        this.featureProjectSwiperImageHeight = 400;
        if (this.featureProductColumnBox) {
            let divElement: HTMLElement = this.featureProjectSwiperBox?.nativeElement;
            this.featureProjectSwiperImageWidth = divElement.clientWidth;
            this.featureProjectSwiperImageHeight = divElement.clientHeight;
        }
    }

    generateImageUrl(baseUrl: string, width: number, height: number): string {
        return `${baseUrl}?tr=w-${width},h-${height}`;
    }
}
