export class ApiConstants {
  /*## OAuth 2.0, Google, Facebook URL Configuration ##*/
  public static LOGIN_URL = 'oauth/token';
  public static LOGIN_WITH_FACEBOOK_URL = 'oauth/facebook';
  public static LOGIN_WITH_GOOGLE_URL = 'oauth/google';
  public static REGISTER_URL = 'user';
  public static USER_VERIFICATION_CODE_URL = 'user/send-verification-code';
  public static USER_RESEND_VERIFICATION_CODE_URL = 'user/resend-verification-code';
  public static USER_VERIFY_CODE_URL = 'user/verify-code';
  /*## OAuth 2.0, Google, Facebook URL Configuration ##*/

  /*## Setting Module URL Configuration ##*/
  public static SETTINGS_URL = 'setting';
  public static SETTING_DETAIL_URL = 'setting/view';
  public static SETTING_UPDATE_URL = 'setting/update';
  public static SETTING_DOWNLOAD_ELIGIBILITY_CRITERIA_URL = 'setting/download-eligibility-criteria';
  /*## Setting Module URL Configuration ##*/

  /*## User Module URL Configuration ##*/
  public static USER_DETAIL_URL = 'oauth/login-user';
  public static USER_FORGOT_PASSWORD_URL = 'user/forgot-password';
  public static USER_CHANGE_PASSWORD_URL = 'user/change-password-modal';
  public static USER_RESET_PASSWORD_URL = 'user/reset-password';
  public static USER_UPDATE_DETAIL_URL = 'oauth/update-user-profile';
  public static USER_UPDATE_PROFILE_PICTURE_URL = 'oauth/update-profile-picture';
  public static USER_UPDATE_ADDRESS_URL = 'frontend-user-address';
  /*## User Module URL Configuration ##*/

  /*## Account Module URL Configuration ##*/
  public static ACCOUNT_BY_ABN_URL = 'account/get-by-abn?expand=companyAddresses,frontendUsers';
  public static ACCOUNT_DETAIL_URL = 'account/';
  public static ACCOUNT_UPDATE_DETAIL_URL = 'account/';
  public static ACCOUNT_UPDATE_ADDRESS_URL = 'account-address/';
  public static ACCOUNT_DELETE_ADDRESS_URL = 'account-address/';
  public static ACCOUNT_TRADE_TYPE_LIST_URL = 'trade-type';
  public static ACCOUNT_BUSINESS_ROLE_LIST_URL = 'business-role';
  public static ACCOUNT_UPDATE_LOGO_URL = 'account/update-logo/';
  /*## Account Module URL Configuration ##*/

  /*## Article Module URL Configuration ##*/
  public static ARTICLE_LIST_URL = 'article';
  public static ARTICLE_DETAIL_URL = 'article/';
  public static ARTICLE_CATEGORY_LIST_URL = 'article-category';
  public static ARTICLE_UPDATE_HELP_FULL_URL = 'article-helpful';
  /*## Article Module URL Configuration ##*/

  /*## Blog Module URL Configuration ##*/
  public static BLOG_LIST_URL = 'blog';
  public static BLOG_DETAIL_URL = 'blog/';
  public static BLOG_CATEGORY_LIST_URL = 'blog-category';
  /*## Blog Module URL Configuration ##*/

  /*## Category Module URL Configuration ##*/
  public static CATEGORY_LIST_URL = 'category';
  public static CATEGORY_DETAIL_URL = 'category/';
  /*## Category Module URL Configuration ##*/

  /*## Color Module URL Configuration ##*/
  public static COLOR_LIST_URL = 'color';
  /*## Color Module URL Configuration ##*/

  /*## Contact Module URL Configuration ##*/
  public static CONTACT_LIST_URL = 'contact';
  public static CONTACT_DETAIL_URL = 'contact/';
  public static CONTACT_SET_COMPANY_ADDRESS_AS_DEFAULT_URL = 'contact/set-company-address-as-default';
  /*## Contact Module URL Configuration ##*/

  /*## Family Module URL Configuration ##*/
  public static FAMILY_LIST_URL = 'family';
  /*## Family Module URL Configuration ##*/

  /*## FAQ Module URL Configuration ##*/
  public static FAQ_LIST_URL = 'faq';
  /*## FAQ Module URL Configuration ##*/

  /*## Favourite Product Module URL Configuration ##*/
  public static FAVOURITE_PRODUCT_LIST_URL = 'favorite-product';
  public static PRODUCT_SET_AS_FAVOURITE_URL = 'favorite-product/create';
  /*## Favourite Product Module URL Configuration ##*/

  /*## Feature Project Module URL Configuration ##*/
  public static FEATURE_PROJECT_LIST_URL = 'project';
  public static FEATURE_PROJECT_DETAIL_URL = 'project';
  public static PROJECT_CATEGORY_LIST_URL = 'project-category';
  /*## Feature Project Module URL Configuration ##*/

  /*## Finish Module URL Configuration ##*/
  public static FINISH_LIST_URL = 'finish';
  /*## Finish Module URL Configuration ##*/

  /*## Gather-co Credit Module URL Configuration ##*/
  public static GATHERCO_CREDIT_LIST_URL = 'gatherco-credit';
  public static GATHERCO_CREDIT_DETAIL_URL = 'gatherco-credit/';
  public static GATHERCO_CREDIT_DONATE_URL = 'gatherco-credit/donate';
  public static GATHERCO_CREDIT_TRANSACTION_LIST_URL = 'gatherco-credit-transaction';
  public static GATHERCO_CREDIT_TRANSACTION_COUNTER_URL = 'gatherco-credit-transaction/counter';
  /*## Gather-co Credit Module URL Configuration ##*/

  /*## Material Module URL Configuration ##*/
  public static MATERIAL_LIST_URL = 'material';
  public static MATERIAL_DETAIL_URL = 'material/';
  /*## Material Module URL Configuration ##*/

  /*## Product Module URL Configuration ##*/
  public static PRODUCT_LIST_URL = 'product';
  public static PRODUCT_DETAIL_URL = 'product/';
  public static PRODUCT_ACCESSORIES_FORMAT_URL = 'product/get-accessories-formats?product_id=';
  public static PRODUCT_FILTER_ACCESSORIES_DROPDOWN_URL = 'product/filter-accessories-dropdown';
  public static PRODUCT_ACCESSORY_TYPE_URL = 'product/get-accessories-types?product_id=';
  public static PRODUCT_FILTER_ACCESSORIES_TYPE_URL = 'product/filter-accessories-types-dropdown';
  public static PRODUCT_DOWNLOAD_URL = 'product/download';
  public static PRODUCT_RECENTLY_VIEWED_URL = 'recently-view-product';
  public static PRODUCT_DOWNLOAD_FORMAT_SIZE_TABLE_URL = 'product/download-format-size-table';
  public static PRODUCT_DOWNLOAD_PRICE_TABLE_URL = 'product/download-price-table';
  public static PRODUCT_CONSUMABLE_CALCULATION_URL = 'product/consumable-calculation';
  /*## Product Module URL Configuration ##*/

  /*## Quote/Order Module URL Configuration ##*/
  public static QUOTE_ORDER_LIST_URL = 'quote-order';
  public static QUOTE_ORDER_DETAIL_URL = 'quote-order/';
  public static QUOTE_ORDER_CHECK_EXPORT_ID_URL = 'quote-order/check-export-id';
  public static QUOTE_ORDER_CREATE_URL = 'quote-order';
  public static QUOTE_ORDER_UPDATE_URL = 'quote-order/';
  public static QUOTE_ORDER_APPLIED_CREDIT_URL = 'quote-order/apply-credit';
  public static QUOTE_ORDER_STATUS_UPDATE_URL = 'quote-order/status-update/';
  public static QUOTE_ORDER_DELETE_URL = 'quote-order/';
  public static QUOTE_ORDER_PRODUCT_CREATE_URL = 'quote-product/create';
  public static QUOTE_ORDER_PRODUCT_DELETE_URL = 'quote-product/';
  public static QUOTE_ORDER_PAYMENT_LIST_URL = 'quote-payment';
  public static QUOTE_ORDER_CALCULATE_CARD_CHARGE_URL = 'quote-order/calculate-card-charge';
  public static QUOTE_ORDER_PAYMENT_VALIDATE_TOKEN_URL = 'quote-payment/validate-token';
  public static QUOTE_ORDER_TRUCK_TYPE_LIST_URL = 'shipping-truck-type';
  public static QUOTE_ORDER_SHIPPING_CALCULATION_URL = 'quote-order/shipping-calculation';
  public static QUOTE_ORDER_FEEDBACK_URL = 'quote-feedback';
  public static QUOTE_ORDER_EXPORT_URL = 'quote-order/export/';
  public static QUOTE_ORDER_EXPORT_RECEIPT_URL = 'quote-order/export-receipt/';
  public static QUOTE_ORDER_DETAIL_BY_ID_URL = 'quote-order/detail';
  public static TRACK_ORDER_DETAIL_BY_ID_URL = 'quote-order/track-order-detail';
  public static QUOTE_ORDER_REACTIVATE_URL = 'quote-order/reactivate-quote';
  public static QUOTE_ORDER_COUNTER_URL = 'quote-order/counter';
  public static QUOTE_ORDER_SAMPLE_PRODUCT_COUNTER_URL = 'quote-order/total-sample-product-counter';
  public static QUOTE_ORDER_DOWNLOAD_URL = 'quote-order/download';
  public static QUOTE_ORDER_DOWNLOAD_FILE_URL = 'quote-order/download-file?file_name=';
  public static QUOTE_ORDER_SHARE_URL = 'quote-order/share';
  public static QUOTE_ORDER_PRODUCT_APPLIED_CALCULATOR_CREATE_URL = 'quote-product-applied-calculator/create';
  public static QUOTE_ORDER_PRODUCT_APPLIED_CALCULATOR_DELETE_URL = 'quote-product-applied-calculator/';
  public static QUOTE_ORDER_RE_CALCULATE_PRICE_URL = 'quote-order/re-calculate-quote-price';
  public static QUOTE_ORDER_DELETE_ALL_PRODUCT_APPLIED_CALCULATOR_URL = 'quote-order/delete-all-quote-product-applied-calculators/';
  public static QUOTE_ORDER_GENERATE_EXPORT_ID_URL = 'quote-order/generate-export-id/';
  public static QUOTE_ORDER_PRODUCT_APPLIED_CALCULATOR_LIST_URL = 'quote-product-applied-calculator';
  public static QUOTE_ORDER_VERIFICATION_CODE_URL = 'quote-order/send-verification-code';
  public static QUOTE_ORDER_RESEND_VERIFICATION_CODE_URL = 'quote-order/resend-verification-code';
  public static QUOTE_ORDER_VERIFY_CODE_URL = 'quote-order/verify-code';
  public static QUOTE_ORDER_UPLOAD_PAYMENT_RECEIPT_URL = 'quote-order-payment-receipt';

  public static QUOTE_ORDER_DOWNLOAD_BY_EXPORT_ID_URL = 'quote-order/download-by-export-id';
  public static QUOTE_ORDER_DOWNLOAD_FILE_BY_EXPORT_ID_URL = 'quote-order/download-file-by-export-id?file_name=';
  public static QUOTE_ORDER_SHARE_BY_EXPORT_ID_URL = 'quote-order/share-by-export-id';
  public static ORDER_SHARE_BY_TRACKING_ID = 'quote-order/share-by-tracking-id';
  public static QUOTE_ORDER_UPDATE_BY_EXPORT_ID_URL = 'quote-order/update-by-export-id';
  public static QUOTE_ORDER_STATUS_UPDATE_BY_EXPORT_ID_URL = 'quote-order/status-update-by-export-id';
  public static QUOTE_ORDER_PAYMENT_LINK_BY_EXPORT_ID_URL = 'quote-payment/create-by-export-id';
  public static QUOTE_ORDER_UPDATE_BY_TRACKING_ID_URL = 'quote-order/update-by-tracking-id';
  public static QUOTE_ORDER_PRODUCT_APPLIED_CALCULATOR_CREATE_BY_EXPORT_ID_URL = 'quote-product-applied-calculator/create-by-export-id';
  public static QUOTE_ORDER_PRODUCT_APPLIED_CALCULATOR_DELETE_BY_EXPORT_ID_URL = 'quote-product-applied-calculator/delete-by-export-id';
  public static QUOTE_ORDER_RE_CALCULATE_PRICE_BY_EXPORT_ID_URL = 'quote-order/re-calculate-quote-price-by-export-id';

  /*## Quote/Order Module URL Configuration ##*/

  /*## Product Recommended Color Module URL Configuration ##*/
  public static PRODUCT_RECOMMENDED_COLOR_LIST_URL = 'product-recommended-color';
  /*## Product Recommended Color Module URL Configuration ##*/

  /*## Product Resource Module URL Configuration ##*/
  public static PRODUCT_RESOURCE_LIST_URL = 'product-resource';
  public static PRODUCT_RESOURCE_CATEGORY_LIST_URL = 'product-resource-category';
  public static PRODUCT_RESOURCE_SUB_CATEGORY_LIST_URL = 'product-resource-sub-category';
  public static PRODUCT_RESOURCE_DOWNLOAD_URL = 'product-resource/download?id=';
  /*## Product Resource Module URL Configuration ##*/

  public static PRODUCT_COLLECTION_LIST_URL = 'product-collection';
  public static PRODUCT_CATEGORY_LIST_URL = 'product-category';
  public static PRODUCT_TYPE_DETAIL_URL = 'product-type/';
}
