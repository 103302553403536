import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-mega-menu-flooring',
  templateUrl: './mega-menu-flooring.component.html',
  styleUrls: ['./mega-menu-flooring.component.css']
})
export class MegaMenuFlooringComponent implements OnInit{
  /* ################### Variable Declaration Start ################### */
  @Output("redirectFromMegaMenu") redirectFromMegaMenu: EventEmitter<any> = new EventEmitter();

  /* ################### Variable Declaration End ################### */  

  /************************************
  * Life cycle hooks start
  ************************************/
  ngOnInit() {
  }

  /************************************
  * Life cycle hooks end
  ************************************/

  /************************************
  * Redirect functions start
  ************************************/
  redirectFromChildMegaMenu(searchType: string, searchId: number, searchName: string) {
    this.redirectFromMegaMenu.emit({searchType: searchType, searchId: searchId, searchName: searchName});
  }

  /************************************
  * Redirect functions end
  ************************************/

}
