<div class="filter-format-section loader-parent">
  <div class="page-loader" [ngClass]="{'d-none': !isFlooringCollectionLoading}"></div>
  <div class="filter-format-inner">
    <ul>
      <li *ngFor="let flooringCollection of flooringCollectionList;trackBy: trackByFn">
        <a
          [routerLink]="['/'+ helperService.createSlug(Constants.FILTER_PRODUCT_WITH_FLOORING)+'/'+helperService.createSlug(flooringCollection.name)]">
          <div class="filter-format-item">
            <div class="format-name">
              <label>{{ flooringCollection.name }}</label>
              <span class="qty-no"></span>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</div>
