<div class="quote-tabs">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link active" data-toggle="tab" [routerLink]="['/flooring']">
        All Flooring
        <i class='fas fa-arrow-right ml-3'></i>
      </a>
    </li>
  </ul>
  <div class="tab-content">
    <div class="tab-pane active">
      <div class="main-sub-title">
        <app-filter-by-flooring (redirectFromChildMegaMenu)="redirectFromChildMegaMenu($event.searchType, $event.searchId, $event.searchName)">
        </app-filter-by-flooring>
      </div>
    </div>
  </div>
</div>
