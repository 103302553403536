<!--<ng-container *ngIf="isPageDetailLoad">-->

<div class="page-loader" [ngClass]="{'d-none': !isLoading}"></div>
<!-- Banner -->
<div class="page-banner-section home-page-banner1 full-height-scroll">
  <img class="desktop banner-img" width="1914" height="auto"
       [ngSrc]="'/WEBSITE_ASSET_STAGING/home-page-banner.jpg'"
       ngSrcset="200w, 400w, 600w, 800w, 1000w, 1200w, 1600w, 1920w, 3000w" sizes="150vw"
       alt="GatherCo Banner" title="GatherCo" priority/>
  <div class="page-banner-container">
    <div class="home-page-banner-content">
      <p class="banner-sub-title">We’re making specification simple.</p>
      <h1 class="page-banner-title"><span>Bespoke Flooring &amp; Walling,</span>Discovered for you.</h1>
      <ng-container *ngIf="isUserAuthenticated">
        <ng-container *ngIf="isTradeUser">
          <button class="btn white-bordered-btn color-black" [routerLink]="['account/dashboard']">
            Get Started
          </button>
        </ng-container>
        <ng-container *ngIf="!isTradeUser">
          <button class="btn white-bordered-btn color-black" [routerLink]="['surfaces']">
            Get Started
          </button>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!isUserAuthenticated">
        <button class="btn white-bordered-btn color-black" (click)="rightSidebarToggle()">
          Get Started
        </button>
      </ng-container>
    </div>
  </div>
</div>
<!-- Banner -->

<!-- All in one -->
<div class="all-in-one">
  <div class="all-in-one-container">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="all-in-one-box">
          <div class="all-in-one-inner">
            <div class="box-dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div #allInOneImageBox style="display: block; height: 100%;">
              <a *ngIf="allInOneProductUrl" [routerLink]="[allInOneProductUrl]">
                <img ngSrc="{{ allInOneProductImage }}" width="777" height="auto" alt="All In One Product Image"
                     title="All In One Product Image"
                     ngSrcset="200w, 400w, 600w, 777w" loading="lazy" sizes="50vw"
                     priority>
                <span class="all-in-one-text">View Product</span>
                <span class="all-in-one-box-pattern">
                  <img ngSrc="{{ '/'+allInOneProductFinishes }}" width="147" height="147" alt="All In One Finish Image"
                       title="All In One Finish Image"
                       ngSrcset="110w, 147w" loading="lazy" sizes="50vw"
                       priority>
                </span>
              </a>
            </div>
          </div>
          <div class="all-in-one-icons"
               *ngIf=" allInOneProduct && allInOneProduct.id > 0 && allInOneProduct?.productSpecificationIcons">
            <ul>
              <li class="icon-all-in-one" *ngFor="let specificationIcon of allInOneProduct.productSpecificationIcons">
                <img ngSrc="{{ s3BuketFinishFolder + '/' + specificationIcon.path }}" width="50" height="50"
                     alt="{{getFileName(specificationIcon.path)}} Icon" ngSrcset="50w" loading="lazy" sizes="50vw"
                     priority>
                <!--                title="{{ specificationIcon.name.toUpperCase() }}"-->
                <span>{{ specificationIcon.name.toLowerCase() }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="all-in-one-right">
          <h2 class="section-title">
            All-in-one <br>platform
          </h2>
          <p class="section-description">
            Maximise efficiency and eliminate confusion with Gather Co.
            Browse, specify and order product at the click of a button.
          </p>
          <ng-container *ngIf="isUserAuthenticated">
            <ng-container *ngIf="isTradeUser">
              <button class="btn white-bordered-btn color-black" [routerLink]="['/account/dashboard']">
                Get Started
              </button>
            </ng-container>
            <ng-container *ngIf="!isTradeUser">
              <button class="btn white-bordered-btn color-black" [routerLink]="[allInOneProductUrl]">
                Get Started
              </button>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!isUserAuthenticated">
            <button class="btn white-bordered-btn color-black" (click)="rightSidebarToggle()">
              Get Started
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- All in one -->
<!-- One place section -->
<div class="one-place-section home-page-banner1">
  <img class="desktop banner-img" width="1920" height="761"
       [ngSrc]="'/WEBSITE_ASSET_STAGING/source-specify-supply-image.png'"
       ngSrcset="200w, 400w, 600w, 800w, 1000w, 1200w, 1600w, 1920w, 3000w" loading="lazy" sizes="50vw"
       alt="Source Specify Supply" title="Source Specify Supply" priority/>
  <div class="one-place-inner-container">
    <p class="su-title">One place to</p>
    <label class="one-place-title">Source, Specify, Supply</label>
    <ng-container *ngIf="isUserAuthenticated && !isTradeUser">
      <button class="btn white-bordered-btn" [routerLink]="['/']">Get Started</button>
    </ng-container>
    <ng-container *ngIf="isUserAuthenticated && isTradeUser">
      <button class="btn white-bordered-btn" [routerLink]="['/account/dashboard']">Get Started</button>
    </ng-container>
    <ng-container *ngIf="!isUserAuthenticated">
      <button class="btn white-bordered-btn" (click)="rightSidebarToggle()">Get Started</button>
    </ng-container>
  </div>
</div>
<!-- One place section -->
<!-- Service List Section -->
<div class="service-list-section">
  <div class="service-list-inner-container">
    <div class="row">
      <div #swiperSlideinnerbox class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-0 service-list-section-slider">
        <swiper [loop]="true" [autoHeight]="true" [autoplay]="{ delay: 3000, disableOnInteraction: false }"
                [pagination]="{ clickable: true }" [navigation]="false">
          <ng-template swiperSlide class="custom-class">
            <img class="desktop banner-img" width="957" height="634"
                 [ngSrc]="'/WEBSITE_ASSET_STAGING/service-slide-1.png'"
                 ngSrcset="200w, 400w, 600w, 800w, 957w" loading="lazy" sizes="50vw"
                 alt="Service Slide" title="Service Slide" priority/>
          </ng-template>
          <ng-template swiperSlide>
            <img class="desktop banner-img" width="957" height="634"
                 [ngSrc]="'/WEBSITE_ASSET_STAGING/service-slide-2.png'"
                 ngSrcset="200w, 400w, 600w, 800w, 957w" loading="lazy" sizes="50vw"
                 alt="Service Slide" title="Service Slide" priority/>
          </ng-template>
          <ng-template swiperSlide>
            <img class="desktop banner-img" width="957" height="634"
                 [ngSrc]="'/WEBSITE_ASSET_STAGING/service-slide-3.png'"
                 ngSrcset="200w, 400w, 600w, 800w, 957w" loading="lazy" sizes="50vw"
                 alt="Service Slide" title="Service Slide" priority/>
          </ng-template>
        </swiper>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 service-list">
        <div class="service-list-inner">
          <p>We’re made for trade</p>
          <ul *ngIf="tradeType && tradeType.length > 0">
            <li *ngFor="let item of tradeType">{{ item.name }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Service List Section -->
<!-- FEATURED PRODUCTS -->
<div class="featured-product featured-product-scroll" *ngIf="featureProduct && featureProduct.length > 0">
  <div class="featured-product-inner">
    <div class="feature-section-title">Featured Products</div>
    <div class="feature-product-grid">
      <div class="image-grid">
        <div #featureProductColumnBox class="column">
          <div class="content" *ngIf="featureProduct[0] && featureProduct[0].id > 0">
            <div class="content-overlay"></div>
            <img width="500" height="500"
                 [ngSrc]="productImagePath(featureProduct[0])"
                 ngSrcset="200w, 400w, 500w" loading="lazy" sizes="50vw"
                 alt="{{getFileName(productImagePath(featureProduct[0]))}}"
                 title="{{getFileName(productImagePath(featureProduct[0]))}}" priority/>
            <div class="content-details">
              <h3>{{ featureProduct[0]?.family_name }}</h3>
              <p
                *ngIf="featureProduct[0].type == Constants.PRODUCT_TYPE_SURFACE || featureProduct[0].type == Constants.PRODUCT_TYPE_SURFACE_ACCESSORY">
                {{ featureProduct[0]?.product_category_name }}</p>
              <p
                *ngIf="featureProduct[0].type == Constants.PRODUCT_TYPE_DECOR || featureProduct[0].type == Constants.PRODUCT_TYPE_DECOR_ACCESSORY">
                {{ featureProduct[0]?.category_name }}</p>
              <a [routerLink]="redirectToProduct(featureProduct[0])">View Product</a>
            </div>
          </div>

          <div class="content" *ngIf="featureProduct[1] && featureProduct[1].id > 0">
            <div class="content-overlay"></div>
            <img width="500" height="500"
                 [ngSrc]="productImagePath(featureProduct[1])"
                 ngSrcset="200w, 400w, 500w" loading="lazy" sizes="50vw"
                 alt="{{getFileName(productImagePath(featureProduct[1]))}}"
                 title="{{getFileName(productImagePath(featureProduct[1]))}}" priority/>
            <div class="content-details">
              <h3>{{ featureProduct[1]?.family_name }}</h3>
              <p
                *ngIf="featureProduct[1].type == Constants.PRODUCT_TYPE_SURFACE || featureProduct[1].type == Constants.PRODUCT_TYPE_SURFACE_ACCESSORY">
                {{ featureProduct[1]?.product_category_name }}
              </p>
              <p
                *ngIf="featureProduct[1].type == Constants.PRODUCT_TYPE_DECOR || featureProduct[1].type == Constants.PRODUCT_TYPE_DECOR_ACCESSORY">
                {{ featureProduct[1]?.category_name }}
              </p>
              <a [routerLink]="redirectToProduct(featureProduct[1])">View Product</a>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="content" *ngIf="featureProduct[2] && featureProduct[2].id > 0">
            <div class="content-overlay"></div>
            <img width="393" height="937"
                 [ngSrc]="productImagePath(featureProduct[2])"
                 ngSrcset="200w, 400w, 500w" loading="lazy" sizes="50vw"
                 alt="{{getFileName(productImagePath(featureProduct[2]))}}"
                 title="{{getFileName(productImagePath(featureProduct[2]))}}" priority/>
            <div class="content-details">
              <h3>{{ featureProduct[2]?.family_name }}</h3>
              <p
                *ngIf="featureProduct[2].type == Constants.PRODUCT_TYPE_SURFACE || featureProduct[2].type == Constants.PRODUCT_TYPE_SURFACE_ACCESSORY">
                {{ featureProduct[2]?.product_category_name }}
              </p>
              <p
                *ngIf="featureProduct[2].type == Constants.PRODUCT_TYPE_DECOR || featureProduct[2].type == Constants.PRODUCT_TYPE_DECOR_ACCESSORY">
                {{ featureProduct[2]?.category_name }}
              </p>
              <a [routerLink]="redirectToProduct(featureProduct[2])">View Product</a>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="content" *ngIf="featureProduct[3] && featureProduct[3].id > 0">
            <div class="content-overlay"></div>
            <img width="500" height="500"
                 [ngSrc]="productImagePath(featureProduct[3])"
                 ngSrcset="200w, 400w, 500w" loading="lazy" sizes="50vw"
                 alt="{{getFileName(productImagePath(featureProduct[3]))}}"
                 title="{{getFileName(productImagePath(featureProduct[3]))}}" priority/>
            <div class="content-details">
              <h3>{{ featureProduct[3]?.family_name }}</h3>
              <p
                *ngIf="featureProduct[3].type == Constants.PRODUCT_TYPE_SURFACE || featureProduct[3].type == Constants.PRODUCT_TYPE_SURFACE_ACCESSORY">
                {{ featureProduct[3]?.product_category_name }}
              </p>
              <p
                *ngIf="featureProduct[3].type == Constants.PRODUCT_TYPE_DECOR || featureProduct[3].type == Constants.PRODUCT_TYPE_DECOR_ACCESSORY">
                {{ featureProduct[3]?.category_name }}
              </p>
              <a [routerLink]="redirectToProduct(featureProduct[3])">View Product</a>
            </div>
          </div>
          <div class="content" *ngIf="featureProduct[4] && featureProduct[4].id > 0">
            <div class="content-overlay"></div>
            <img width="500" height="500"
                 [ngSrc]="productImagePath(featureProduct[4])"
                 ngSrcset="200w, 400w, 500w" loading="lazy" sizes="50vw"
                 alt="{{getFileName(productImagePath(featureProduct[4]))}}"
                 title="{{getFileName(productImagePath(featureProduct[4]))}}" priority/>
            <div class="content-details">
              <h3>{{ featureProduct[4]?.family_name }}</h3>
              <p
                *ngIf="featureProduct[4].type == Constants.PRODUCT_TYPE_SURFACE || featureProduct[4].type == Constants.PRODUCT_TYPE_SURFACE_ACCESSORY">
                {{ featureProduct[4]?.product_category_name }}
              </p>
              <p
                *ngIf="featureProduct[4].type == Constants.PRODUCT_TYPE_DECOR || featureProduct[4].type == Constants.PRODUCT_TYPE_DECOR_ACCESSORY">
                {{ featureProduct[4]?.category_name }}
              </p>
              <a [routerLink]="redirectToProduct(featureProduct[4])">View Product</a>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="content" *ngIf="featureProduct[5] && featureProduct[5].id > 0">
            <div class="content-overlay"></div>
            <img width="393" height="937"
                 [ngSrc]="productImagePath(featureProduct[5])"
                 ngSrcset="200w, 400w, 500w" loading="lazy" sizes="50vw"
                 alt="{{getFileName(productImagePath(featureProduct[5]))}}"
                 title="{{getFileName(productImagePath(featureProduct[5]))}}" priority/>
            <div class="content-details">
              <h3>{{ featureProduct[5]?.family_name }}</h3>
              <p
                *ngIf="featureProduct[5].type == Constants.PRODUCT_TYPE_SURFACE || featureProduct[5].type == Constants.PRODUCT_TYPE_SURFACE_ACCESSORY">
                {{ featureProduct[5]?.product_category_name }}
              </p>
              <p
                *ngIf="featureProduct[5].type == Constants.PRODUCT_TYPE_DECOR || featureProduct[5].type == Constants.PRODUCT_TYPE_DECOR_ACCESSORY">
                {{ featureProduct[5]?.category_name }}
              </p>
              <a [routerLink]="redirectToProduct(featureProduct[5])">View Product</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- FEATURED PRODUCTS -->
<!-- Service List Section - 2 -->
<div class="service-list-section grey-bg service-list-2">
  <div class="service-list-inner-container">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 service-list">
        <div class="service-list-inner">
          <p>All of this and more</p>
          <ul>
            <li>Design files</li>
            <li>Trade pricing</li>
            <li>Full product details</li>
            <li>Quoting</li>
            <li>Invoicing</li>
            <li>Accurate lead times</li>
            <li>Imagery</li>
            <li>Free Samples</li>
          </ul>
          <button class="btn white-bordered-btn" *ngIf="isUserAuthenticated && !isTradeUser"
                  [routerLink]="['/']">
            Get Started
          </button>
          <button class="btn white-bordered-btn" *ngIf="isUserAuthenticated && isTradeUser"
                  [routerLink]="['/account/dashboard']">
            Get Started
          </button>
          <button class="btn white-bordered-btn" *ngIf="!isUserAuthenticated"
                  (click)="rightSidebarToggle()">
            Get Started
          </button>
        </div>
      </div>
      <div #serviceListImageBox class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 service-image-bg pr-0">
        <img [ngSrc]="'/WEBSITE_ASSET_STAGING/service-list-image.png'" width="942" height="601"
             ngSrcset="200w, 400w, 600w, 800w, 942w" loading="lazy" sizes="50vw"
             alt="Service list image" title="Service list" priority/>
      </div>
    </div>
  </div>
</div>
<!-- Service List Section - 2 -->
<!-- Make us different -->
<div class="make-us-different">
  <div class="make-us-container">
    <div class="row align-items-center">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="make-us-left">
          <label class="section-title">
            What makes <br/>
            us different?
          </label>
          <p class="section-description">
            We get that streamlining specification makes your life easier.
          </p>
          <p class="section-description">
            Our custom platform has been specifically developed for industry professionals. Get all the information you
            need, when you need it.
          </p>
          <p class="section-description">
            Our Mission? A seamless design experience, flawless project outcomes and happy clients.
          </p>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
            <div class="make-us-widget make-us-widget-custom">
              <div class="widget-imaage">
                <img [ngSrc]="'/WEBSITE_ASSET_STAGING/resource.svg'" width="50" height="51"
                     ngSrcset="50w" loading="lazy" sizes="50vw"
                     alt="Resource" title="Resource" priority/>
              </div>
              <span class="widget-title">
                  Resource Hub
                </span>
              <p>
                Architectural resources at your finger tips.
              </p>
              <a [routerLink]="['/resource']">
                Learn More About Resource Hub
              </a>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
            <div class="make-us-widget make-us-widget-custom">
              <div class="widget-imaage">
                <img [ngSrc]="'/WEBSITE_ASSET_STAGING/support.svg'" width="50" height="51"
                     ngSrcset="50w" loading="lazy" sizes="50vw"
                     alt="Support" title="Support" priority/>
              </div>
              <span class="widget-title">
                  Support
                </span>
              <p>
                Get in touch - our business is to support yours.
              </p>
              <a [routerLink]="['/contact-us']">
                Learn More About Contact
              </a>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
            <div class="make-us-widget">
              <div class="widget-imaage">
                <img [ngSrc]="'/WEBSITE_ASSET_STAGING/reward.svg'" width="50" height="51"
                     ngSrcset="50w" loading="lazy" sizes="50vw"
                     alt="Reward" title="Reward" priority/>
              </div>
              <span class="widget-title">
                  Rewards
                </span>
              <p>
                Thoughtful features to reward your loyalty.
              </p>
              <a [routerLink]="['/gather-commitment']">
                Learn more About loyalty
              </a>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
            <div class="make-us-widget">
              <div class="widget-imaage">
                <img [ngSrc]="'/WEBSITE_ASSET_STAGING/samples.svg'" width="50" height="51"
                     ngSrcset="50w" loading="lazy" sizes="50vw"
                     alt="Samples" title="Samples" priority/>
              </div>
              <span class="widget-title">
                  Samples
                </span>
              <p>
                Free samples online, delivered directly to you.
              </p>
              <a [routerLink]="['/samples']">
                Learn more About samples
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Make us different -->
<!-- Explore surface & Decor -->
<div class="none-fixed-content explore-expanded">
  <div class="explore">
    <div class="row align-items-center">
      <a class=" col-lg-6 col-md-6 col-sm-6 col-12 surface-and-decore-hover surface-explore home-page-banner1"
         [routerLink]="['/flooring']">
        <!--                    <img class="desktop banner-img" loading="lazy" src="https://ik.imagekit.io/c0mm9a4xe/WEBSITE_ASSET_STAGING/explore-expanded-image1.png?tr=w-952,h-839" alt="">-->
        <div class="explore-box">
          <h3 class="su-title">Explore</h3>
          <h3>Flooring</h3>
        </div>
      </a>
      <div class="col-lg-6 col-md-6 col-sm-6 col-12 surface-and-decore-hover decore-explore home-page-banner1"
      [routerLink]="['/walling']">
        <div class="explore-box">
          <h3 class="su-title">Explore</h3>
          <h3>Walling</h3>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Explore surface & Decor -->
<!-- Slider -->
<div class="featured-product featured-projects" *ngIf="featureProject && featureProject.length > 0">
  <div class="feature-section-title">FEATURED PROJECTS</div>
  <div class="swiper feature-product-carousel">
    <swiper [config]="exampleConfig">
      <ng-container *ngFor="let project of featureProject">
        <ng-template swiperSlide>
          <a class="feature-product-item" [routerLink]="['/project/',project.id,'all']" #featureProjectSwiperBox>
            <img [ngSrc]="'/'+project.image_1" width="878" height="454" class="project-image"
                 ngSrcset="200w, 400w, 600w, 878w" loading="lazy" sizes="50vw"
                 alt="{{getFileName(project.image_1)}}" title="{{getFileName(project.image_1)}}" priority/>
            <div class="product-inner-box">
              <div class="product-inner-row">
                <label class="product-name">
                  <span>{{ project.name }}</span>
                </label>
                <span>{{project.architect_name ? removeHtmlTags(project.architect_name) : ''}}</span>
              </div>
            </div>
          </a>
        </ng-template>
      </ng-container>
    </swiper>
    <div class="navigation-pagination-container">
      <div class="product-slider-navigation">
        <div id="product-left" #prevEl>
          <span class="icon-left-caret"></span>
        </div>
        <div class="product-pagination" #pagination></div>
        <div id="product-right" #nextEl>
          <span class="icon-right-caret"></span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Slider -->
<!-- Gatherco credit scrore -->
<div class="two-shade-section gatherco-credit-section home-gatherco-credit-wrap">
  <div class="two-shade-left">
    <div class="two-shade-content">
      <div class="earn-value-content go-commit-left-text">
        <label class="text-left-get">Get more with the <br/>Gather Co-mmitment</label>
        <ng-container *ngIf="!isUserAuthenticated">
          <p>Spend & Earn</p>
          <p class="text-left-get">Sign up to a trade account and earn 3c on every dollar in credit with
            every online
            purchase.</p>
          <button class="btn white-bordered-btn color-black" [routerLink]="['/gather-commitment']">
            Learn More
          </button>
        </ng-container>
        <ng-container *ngIf="isUserAuthenticated">
          <p class="signin-text-first">Your trust in our brand is something we take very seriously.</p>
          <p class="text-left-get">As an expression of our appreciation, we've committed to crediting your
            account
            with
            3c on every dollar you spend with us online.</p>
          <button class="btn white-bordered-btn color-black" [routerLink]="['/gather-commitment']">
            Learn More
          </button>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="two-shade-right">
    <div class="two-shade-content">
      <div class="gather-credit-title">Gather <br/>Co Credit</div>
      <div class="plant-image">
        <img src="{{Constants.S3_BUCKET_URL}}/hand-with-plant-white.gif" alt="Hand With Plant White"
             title="Hand With Plant White"
             loading="lazy"/>
        <!--        <img [ngSrc]="'/WEBSITE_ASSET_STAGING/hand-with-plant-white.gif'" width="50" height="51"-->
        <!--             ngSrcset="200w, 400w, 600w, 800w, 1000w, 1200w, 1600w, 2000w, 3000w" loading="lazy"-->
        <!--             alt="Hand With Plant White" title="Hand With Plant White" priority/>-->
      </div>
      <div class="earn-value">
        <label class="earn-value-title">
          <span>3<small>c</small></span>&nbsp; in every &nbsp; <span>$1</span>
        </label>
        <p>
          Accumulate 3 cents in Gather Co-mmitment credit with every online
          dollar spent.
        </p>
      </div>
    </div>
  </div>
</div>

<!-- Gatherco credit scrore -->
<!-- Our Client -->
<div class="our-client-section">
  <div class="feature-section-title">Industry leaders we’re working with</div>
  <div class="client-carousel-container">
    <div class="client-testimonial-arrow">
      <div class="prev-client client-slider-arrow">
        <span class="icon-left-caret"></span>
      </div>
    </div>

    <div class="client-carousel">
      <div class="swiper-wrapper">
        <swiper [loop]="true" [navigation]="{ prevEl: '.prev-client', nextEl: '.next-client' }"
                [autoplay]="{ delay: 500, disableOnInteraction: false }"
                [breakpoints]="{ '300': { slidesPerView: 3, spaceBetween: 11 }, '768': { slidesPerView: 5, spaceBetween: 30 }, '1300': { slidesPerView: 6, spaceBetween: 40 }, '1500': { slidesPerView: 6, spaceBetween: 42 } }">
          <ng-template swiperSlide>
            <a href="https://www.acme-co.com.au/" target="_blank">
              <img ngSrc="/WEBSITE_ASSET_STAGING/client-logo/acme.png?tr=w-159,h-74,bg-FFFFFF"
                   width="159" height="74" priority
                   (error)="helperService.getErrorImage($event, 'client.png')"
                   alt="Acme" title="Acme"/>
            </a>
          </ng-template>
          <ng-template swiperSlide>
            <a href="https://www.collinsandturner.com/" target="_blank">
              <img ngSrc="/WEBSITE_ASSET_STAGING/client-logo/collins-turner-architects.png?tr=w-159,h-74,bg-FFFFFF"
                   width="159" height="74" priority
                   (error)="helperService.getErrorImage($event, 'client.png')"
                   alt="Collins Turner Architects" title="Collins Turner Architects"/>
            </a>
          </ng-template>
          <ng-template swiperSlide>
            <a href="https://dangarbarinsmith.com.au/" target="_blank">
              <img ngSrc="/WEBSITE_ASSET_STAGING/client-logo/dangar-barin-smith.png?tr=w-159,h-74,bg-FFFFFF"
                   width="159" height="74" priority
                   (error)="helperService.getErrorImage($event, 'client.png')"
                   alt="Dangar Barin Smith" title="Dangar Barin Smith"/>
            </a>
          </ng-template>
          <ng-template swiperSlide>
            <a href="https://dbshomes.com.au/" target="_blank">
              <img ngSrc="/WEBSITE_ASSET_STAGING/client-logo/dbs-homes.png?tr=w-159,h-74,bg-FFFFFF"
                   width="159" height="74" priority
                   (error)="helperService.getErrorImage($event, 'client.png')"
                   alt="Dbs Homes" title="Dbs Homes"/>
            </a>
          </ng-template>
          <ng-template swiperSlide>
            <a href="https://www.eje.com.au/" target="_blank">
              <img ngSrc="/WEBSITE_ASSET_STAGING/client-logo/eje-architecture.png?tr=w-159,h-74,bg-FFFFFF"
                   width="159" height="74" priority
                   (error)="helperService.getErrorImage($event, 'client.png')"
                   alt="Eje Architecture" title="Eje Architecture"/>
            </a>
          </ng-template>
          <ng-template swiperSlide>
            <a href="https://www.instagram.com/esoteriko_interior/?hl=en" target="_blank">
              <img ngSrc="/WEBSITE_ASSET_STAGING/client-logo/esoteriko-interior-design.png?tr=w-159,h-74,bg-FFFFFF"
                   width="159" height="74" priority
                   (error)="helperService.getErrorImage($event, 'client.png')"
                   alt="Esoteriko Interior Design" title="Esoteriko Interior Design"/>
            </a>
          </ng-template>
          <ng-template swiperSlide>
            <a href="https://www.fabricarchitecture.com.au/" target="_blank">
              <img ngSrc="/WEBSITE_ASSET_STAGING/client-logo/fabric-architecture.png?tr=w-159,h-74,bg-FFFFFF"
                   width="159" height="74" priority
                   (error)="helperService.getErrorImage($event, 'client.png')"
                   alt="Fabric Architecture" title="Fabric Architecture"/>
            </a>
          </ng-template>
          <ng-template swiperSlide>
            <a href="https://outdoorestablishments.com/" target="_blank">
              <img ngSrc="/WEBSITE_ASSET_STAGING/client-logo/outdoor-establishments.png?tr=w-159,h-74,bg-FFFFFF"
                   width="159" height="74" priority
                   (error)="helperService.getErrorImage($event, 'client.png')"
                   alt="Outdoor Establishments" title="Outdoor Establishments"/>
            </a>
          </ng-template>
          <ng-template swiperSlide>
            <a href="https://www.pollyharbison.com.au/" target="_blank">
              <img ngSrc="/WEBSITE_ASSET_STAGING/client-logo/polly-harbison-design.png?tr=w-159,h-74,bg-FFFFFF"
                   width="159" height="74" priority
                   (error)="helperService.getErrorImage($event, 'client.png')"
                   alt="Polly Harbison Design" title="Polly Harbison Design"/>
            </a>
          </ng-template>
          <ng-template swiperSlide>
            <a href="https://thesocietyinc.com.au/" target="_blank">
              <img ngSrc="/WEBSITE_ASSET_STAGING/client-logo/the-society-inc.png?tr=w-159,h-74,bg-FFFFFF"
                   width="159" height="74" priority
                   (error)="helperService.getErrorImage($event, 'client.png')"
                   alt="The Society Inc" title="The Society Inc"/>
            </a>
          </ng-template>
          <ng-template swiperSlide>
            <a href="http://www.tommarkhenry.studio/" target="_blank">
              <img ngSrc="/WEBSITE_ASSET_STAGING/client-logo/tom-mark-henry.png?tr=w-159,h-74,bg-FFFFFF"
                   width="159" height="74" priority
                   (error)="helperService.getErrorImage($event, 'client.png')"
                   alt="Tom Mark Henry" title="Tom Mark Henry"/>
            </a>
          </ng-template>
          <ng-template swiperSlide>
            <a href="https://wyerandco.com.au/" target="_blank">
              <img ngSrc="/WEBSITE_ASSET_STAGING/client-logo/wyer-co.png?tr=w-159,h-74,bg-FFFFFF"
                   width="159" height="74" priority
                   (error)="helperService.getErrorImage($event, 'client.png')"
                   alt="Wyer Co" title="Wyer Co"/>
            </a>
          </ng-template>
          <ng-template swiperSlide>
            <a href="https://www.inspiredexteriors.com.au/" target="_blank">
              <img ngSrc="/WEBSITE_ASSET_STAGING/client-logo/inspired-exteriors.png?tr=w-159,h-74,bg-FFFFFF"
                   width="159" height="74" priority
                   (error)="helperService.getErrorImage($event, 'client.png')"
                   alt="Inspired Exteriors" title="Inspired Exteriors"/>
            </a>
          </ng-template>
          <ng-template swiperSlide>
            <a href="https://thosearchitects.com.au/" target="_blank">
              <img ngSrc="/WEBSITE_ASSET_STAGING/client-logo/those-architects.png?tr=w-159,h-74,bg-FFFFFF"
                   width="159" height="74" priority
                   (error)="helperService.getErrorImage($event, 'client.png')"
                   alt="Those Architects" title="Those Architects"/>
            </a>
          </ng-template>
          <ng-template swiperSlide>
            <a href="https://ysg.studio/" target="_blank">
              <img ngSrc="/WEBSITE_ASSET_STAGING/client-logo/ysg-studio.png?tr=w-159,h-74,bg-FFFFFF"
                   width="159" height="74" priority
                   (error)="helperService.getErrorImage($event, 'client.png')"
                   alt="Ysg Studio" title="Ysg Studio"/>
            </a>
          </ng-template>
          <ng-template swiperSlide>
            <a href="https://www.landart.com.au/" target="_blank">
              <img ngSrc="/WEBSITE_ASSET_STAGING/client-logo/landart.png?tr=w-159,h-74,bg-FFFFFF"
                   width="159" height="74" priority
                   (error)="helperService.getErrorImage($event, 'client.png')"
                   alt="Landart" title="Landart"/>
            </a>
          </ng-template>
          <ng-template swiperSlide>
            <a href="https://www.luchettikrelle.com/" target="_blank">
              <img ngSrc="/WEBSITE_ASSET_STAGING/client-logo/luchetti-krelle.png?tr=w-159,h-74,bg-FFFFFF"
                   width="159" height="74" priority
                   (error)="helperService.getErrorImage($event, 'client.png')"
                   alt="Luchetti Krelle" title="Luchetti Krelle"/>
            </a>
          </ng-template>
        </swiper>
      </div>
    </div>
    <div class="client-testimonial-arrow">
      <div class="next-client client-slider-arrow">
        <span class="icon-right-caret"></span>
      </div>
    </div>
  </div>
</div>
<!-- Our Client -->

<!--</ng-container>-->
