import {ChangeDetectorRef, Component, EventEmitter, Output} from '@angular/core';
import {PaginationModel, RequestParamModel} from '@coremodels';
import {CommonConstants as Constants} from 'src/app/core/constants/common-constants';
import {HttpErrorResponse} from '@angular/common/http';
import {HelperService, ProductsService} from '@coreservices';

@Component({
  selector: 'app-filter-by-walling',
  templateUrl: './filter-by-walling.component.html',
  styleUrls: ['./filter-by-walling.component.css', '../mega-menu.component.css']
})
export class FilterByWallingComponent {
  /* ################### Variable Declaration Start ################### */
  wallingCollectionList!: any;
  Constants = Constants;
  isWallingCollectionLoading = false;
  @Output("redirectFromChildMegaMenu") redirectFromChildMegaMenu: EventEmitter<any> = new EventEmitter();

  /* ################### Variable Declaration End ################### */

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private productService: ProductsService,
    public helperService: HelperService,
  ) {
  }

  /************************************
   * Life cycle hooks start
   ************************************/
  async ngOnInit() {
    this.getProductCollections();
  }

  /************************************
   * Life cycle hooks end
   ************************************/

  /************************************
   * Other functions start
   ************************************/
  getProductCollections() {
    const wallingCollectionDataString = localStorage.getItem('wallingCollections');
    if (wallingCollectionDataString != null) {
      this.wallingCollectionList = JSON.parse(wallingCollectionDataString) as any;
      let requestParamModel = new RequestParamModel();
      requestParamModel.pageSize = Constants.PAGE_SIZE_ALL;
      requestParamModel.fields = ['id', 'name'];
      requestParamModel.addDefaultFilter('product_type_id', Constants.WALLING_TYPE_ID, 'number');
      requestParamModel.sortChange('name', 'asc')
      this.productService.getProductCollectionList(requestParamModel).subscribe((dataList: PaginationModel<any>) => {
        requestParamModel.update<any>(dataList);
        this.wallingCollectionList = dataList.data;
        localStorage.setItem('wallingCollections', JSON.stringify(this.wallingCollectionList));
        this.changeDetectorRef.detectChanges();
      }, (error: HttpErrorResponse) => {
      });
    } else {
      this.isWallingCollectionLoading = true;
      let requestParamModel = new RequestParamModel();
      requestParamModel.pageSize = Constants.PAGE_SIZE_ALL;
      requestParamModel.fields = ['id', 'name'];
      requestParamModel.addDefaultFilter('product_type_id', Constants.WALLING_TYPE_ID, 'number');
      requestParamModel.sortChange('name', 'asc')
      this.productService.getProductCollectionList(requestParamModel).subscribe((dataList: PaginationModel<any>) => {
        requestParamModel.update<any>(dataList);
        this.wallingCollectionList = dataList.data;
        localStorage.setItem('wallingCollections', JSON.stringify(this.wallingCollectionList));
        this.isWallingCollectionLoading = false;
        this.changeDetectorRef.detectChanges();
      }, (error: HttpErrorResponse) => {
        this.isWallingCollectionLoading = false;
      });
    }
  }

  trackByFn(index: any) {
    return index
  }

  /************************************
   * Other functions end
   ************************************/
}
