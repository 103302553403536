<div class="my-order-panel" [ngClass]="{ 'active-panel': rightDrawerToggle }">
  <div class="page-loader" [ngClass]="{ 'd-none': !isLoading }"></div>
  <div class="panel-header">
    <span class="icon-close close-quote-step-1" (click)="toggleSidebar()"></span>
    <div class="my-order-quantity view-sample-btn">
      <a [routerLink]="['/account/samples']">View Samples</a>
    </div>
    <div class="my-order-quantity">
      <span class="order-count">{{ sampleOrders?.length || 0 }}</span>
      <span class="icon-delivery-o cartitem"></span>
    </div>
  </div>
  <ng-container *ngIf="sampleOrders && sampleOrders != undefined && sampleOrders.length > 0">
    <ng-container *ngIf="!informationShow">
      <div class="panel-body">
        <div class="account-type">
          <div class="row">
            <div class="col-5">
              <h3 class="account-title">My Sample Order</h3>
            </div>
            <div class="col-7">
              <div class="sample-order-dots">
                <ul>
                  <ng-container *ngFor="let in of counter(6); let i = index">
                    <li [ngClass]="{ active: i < sampleOrders?.length }"></li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="added-item-list">
          <ul>
            <ng-container *ngFor="let sampleProduct of sampleOrders; let in = index">
              <li class="product_{{ sampleProduct.id }}">
                <div class="added-item-detail">
                  <div class="item-image">
                    <img src="{{
                          s3BucketImageURL +
                            '/' +
                            sampleProduct?.formatHeroImage?.path +
                            IMAGE_SIZE_69_X_69
                        }}" alt="{{getFileName(sampleProduct?.formatHeroImage?.path)}}"
                         (error)="helperService.getErrorImage($event)"/>
                  </div>
                  <div class="item-other-details">
                    <div class="item-title-price">
                      <div class="item-name">
                        <h3>{{ sampleProduct?.family?.name }} - SAMPLE</h3>
                        <h4>{{ sampleProduct.product_collection_name }}{{ sampleProduct.product_category_name ? ' - ' + sampleProduct.product_category_name : '' }}</h4>
                      </div>
                      <div class="item-price">
                        <span>
                          <span>
                            {{
                           sampleProductPrice ?sampleProductPrice : 0
                              | currency
                            }}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div class="item-other-options">
                      <div id="showmore_{{ sampleProduct.id }}" class="collapse" [ngClass]="{
                            show: sampleProduct.expandMoreDetails === true
                          }">
                        <div class="show-more-details">
                          <ul>
                            <li>
                              <div class="more-details">
                                <label>Material:</label>
                                {{ sampleProduct?.material?.name }}
                              </div>
                            </li>
                            <li>
                              <div class="more-details">
                                <label>Size:</label>
                                {{(sampleProduct?.length + (sampleProduct?.width ? ' x ' +
                                sampleProduct?.width : '')).replace('mm', '') }}
                              </div>
                            </li>
                            <li>
                              <div class="more-details">
                                <label>Finish:</label>
                                {{ sampleProduct?.finish_name }}
                              </div>
                            </li>
                            <li>
                              <div class="more-details">
                                <label>Edge:</label>
                                {{ sampleProduct?.edge_name }}
                              </div>
                            </li>
                            <li>
                              <div class="more-details">
                                <label>Thickness:</label>
                                {{ sampleProduct?.thickness }}
                              </div>
                            </li>
                            <li>
                              <div class="more-details">
                                <label>Quantity</label>
                                {{ sampleProduct?.moi }} PIECE(S)
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <a href="javascript:void(0);" class="custom-confirmation-popup sidebar_remove_product"
                         (click)="deleteQuoteProduct(sampleProduct.id)">Remove</a>
                      <a data-toggle="collapse" data-target="#showmore_{{ sampleProduct.id }}"
                         [attr.aria-expanded]="sampleProduct.expandMoreDetails" (click)="
                            toggleMoreDetailsExpand(
                              sampleProduct.id,
                              sampleProduct.expandMoreDetails
                            )
                          "></a>
                    </div>
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="quote-step-2-total">
          <div class="my-order-total bt-none">
            <span class="order-total-label">Total Value <small>(Ex. GST)</small></span>
            <span class="order-total-amount">
              <span>
                {{ sampleProductPrice| currency }}
              </span>
            </span>
          </div>
          <div class="my-order-total">
            <span class="order-total-label">Total Charge <small>(Ex. GST)</small></span>
            <span class="order-total-amount">
              <span>
                {{ 0 | currency }}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="pannel-footer">
        <div class="my-order-checkout-btn">
          <button *ngIf="todayTotalSampleOrders == 0" class="btn grey-bg-btn create-quote-order"
                  (click)="checkoutProceed()">
            Check out
          </button>
          <button class="btn grey-bg-btn create-quote-order btn-disabled" [tooltip]="
                'You can place only 1 sample order per day with 6 items. Your sample order limit has been exceeded.'
              " [adaptivePosition]="true" *ngIf="todayTotalSampleOrders !== 0">
            Check out
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="informationShow">
      <form id="sample_order_add_new_address" [formGroup]="cartQuoteForm" (ngSubmit)="onSubmitQuote()" method="post"
            class="add_new_address">
        <div class="panel-body">
          <div class="account-type">
            <h3 class="account-title">Information</h3>

          </div>
          <div class="project-refrence">
            <label class="side-panel-sample-lable">Project Name <span
              class="required-field">*</span></label>
            <input class="form-control side-panel-sample-field" [ngClass]="{
                  'is-invalid':
                    (cartQuoteForm.controls['project_name'].invalid &&
                      cartQuoteForm.controls['project_name'].touched) ||
                    submittedMain ||
                    cq.project_name.errors?.apiError,
                  'is-valid': !cartQuoteForm.controls['project_name'].invalid
                }" formControlName="project_name" type="text" placeholder="" required/>
            <span class="invalid-feedback" role="alert" *ngIf="
                  (cartQuoteForm.controls['project_name'].invalid &&
                    cartQuoteForm.controls['project_name'].touched) ||
                  submittedMain ||
                  cq.project_name.errors?.apiError
                ">
              <span *ngIf="cq.project_name.errors?.required">Please enter project name</span>
            </span>
          </div>
          <div class="project-refrence">
            <label class="side-panel-sample-lable">Description</label>
            <input class="form-control side-panel-sample-field" formControlName="description" type="text"/>
          </div>
          <div class="account-type">
            <h3 class="account-title">Recipient</h3>
          </div>
          <div class="free-order-address">
            <div class="address-item-box new-address-checkbox">
              <div class="address-box-inner new-address-container">
                <div class="">
                  <div class="row">
                    <div class="col-12 new-address-field">
                      <label class="side-panel-sample-lable">Name <span
                        class="required-field">*</span></label>
                      <input class="form-control side-panel-sample-field" [ngClass]="{
                              'is-invalid':
                                (cartQuoteForm.controls['site_contact_name']
                                  .invalid &&
                                  cartQuoteForm.controls['site_contact_name']
                                    .touched) ||
                                submittedMain ||
                                cq.site_contact_name.errors?.apiError,
                              'is-valid':
                                !cartQuoteForm.controls['site_contact_name']
                                  .invalid
                            }" formControlName="site_contact_name" type="text" placeholder="" required/>
                      <span class="invalid-feedback" role="alert" *ngIf="
                              (cartQuoteForm.controls['site_contact_name']
                                .invalid &&
                                cartQuoteForm.controls['site_contact_name']
                                  .touched) ||
                              submittedMain ||
                              cq.site_contact_name.errors?.apiError
                            ">
                          <span *ngIf="cq.site_contact_name.errors?.required">Please
                                    enter name</span>
                          <span *ngIf="cq.site_contact_name.errors?.apiError">{{
                            cq.site_contact_name.errors?.apiError
                            }}</span>
                        </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 new-address-field">
                      <label class="side-panel-sample-lable">Mobile Number <span
                        class="required-field">*</span></label>
                      <input class="form-control side-panel-sample-field" [imask]="{mask: '0000 000 000',lazy:false}"
                             [unmask]="true" [ngClass]="{
                              'is-invalid':
                                (cartQuoteForm.controls['site_contact_number']
                                  .invalid &&
                                  cartQuoteForm.controls['site_contact_number']
                                    .touched) ||
                                submittedMain ||
                                cq.site_contact_number.errors?.apiError,
                              'is-valid':
                                !cartQuoteForm.controls['site_contact_number']
                                  .invalid
                            }" formControlName="site_contact_number" type="text" placeholder="" required
                             numbersOnly="true"/>
                      <span class="invalid-feedback" role="alert" *ngIf="
                              (cartQuoteForm.controls['site_contact_number']
                                .invalid &&
                                cartQuoteForm.controls['site_contact_number']
                                  .touched) ||
                              submittedMain ||
                              cq.site_contact_number.errors?.apiError
                            ">
                          <span *ngIf="cq.site_contact_number.errors?.required">Please
                                    enter mobile number</span>
                          <span
                            *ngIf="cq.site_contact_number.errors?.minlength && !cartQuoteForm.controls['site_contact_number'].errors?.mobileNotValid">Please
                                    enter at least 10 characters</span>
                          <span
                            *ngIf="!cartQuoteForm.controls['site_contact_number'].errors?.required && cartQuoteForm.controls['site_contact_number'].errors?.mobileNotValid">Must
                                    be a valid Australian mobile number</span>
                          <span *ngIf="cq.site_contact_number.errors?.apiError">{{
                            cq.site_contact_number.errors?.apiError
                            }}</span>
                        </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 new-address-field">
                      <label class="side-panel-sample-lable">Email </label>
                      <input class="form-control side-panel-sample-field" [ngClass]="{
                              'is-invalid':
                                (cartQuoteForm.controls['site_contact_email']
                                  .invalid &&
                                  cartQuoteForm.controls['site_contact_email']
                                    .touched) ||
                                submittedMain ||
                                cq.site_contact_email.errors?.apiError,
                              'is-valid':
                                !cartQuoteForm.controls['site_contact_email']
                                  .invalid
                            }" formControlName="site_contact_email" type="text" placeholder="" required/>
                      <span class="invalid-feedback" role="alert" *ngIf="
                              (cartQuoteForm.controls['site_contact_email']
                                .invalid &&
                                cartQuoteForm.controls['site_contact_email']
                                  .touched) ||
                              submittedMain ||
                              cq.site_contact_email.errors?.apiError
                            ">
                          <span *ngIf="cq.site_contact_email.errors?.email">Please enter valid email address</span>
                          <span *ngIf="cq.site_contact_email.errors?.apiError">{{
                            cq.site_contact_email.errors?.apiError
                            }}</span>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="account-type">
            <h3 class="account-title">Delivery address</h3>
          </div>
          <div class="free-order-address">
            <div class="address-item-box new-address-checkbox">
              <div class="address-box-inner new-address-container">
                <div class="">
                  <form id="sample_order_add_new_address" [formGroup]="frontendUserAddressForm" (ngSubmit)="onSubmit()"
                        method="post" class="add_new_address">
                    <div class="row">
                      <div class="col-12 new-address-field">
                        <label class="side-panel-sample-lable">Address Line 1<span
                          class="required-field">*</span></label>
                        <input class="form-control side-panel-sample-field" [ngClass]="{
                              'is-invalid':
                                (frontendUserAddressForm.controls[
                                  'address_line_1'
                                ].invalid &&
                                  frontendUserAddressForm.controls[
                                    'address_line_1'
                                  ].touched) ||
                                submittedMain ||
                                fua.address_line_1.errors?.apiError ||
                                fua.address_line_1.errors?.streetRequired,
                              'is-valid':
                                !frontendUserAddressForm.controls[
                                  'address_line_1'
                                ].invalid
                            }" formControlName="address_line_1" type="text" placeholder=""
                               ngx-google-places-autocomplete #placesRef="ngx-places" [options]="options"
                               (onAddressChange)="handleAddressChange($event)" required/>
                        <span class="invalid-feedback" role="alert" *ngIf="
                              (frontendUserAddressForm.controls['address_line_1']
                                .invalid &&
                                frontendUserAddressForm.controls['address_line_1']
                                  .touched) ||
                              submittedMain ||
                              fua.address_line_1.errors?.apiError ||
                              fua.address_line_1.errors?.streetRequired
                            ">
                          <span *ngIf="fua.address_line_1.errors?.required">Please enter address line 1</span>
                          <span *ngIf="fua.address_line_1.errors?.apiError">{{
                            fua.address_line_1.errors?.apiError
                            }}</span>
                          <span *ngIf="fua.address_line_1.errors?.streetRequired">{{
                            fua.address_line_1.errors?.streetRequired
                            }}</span>
                          <span
                            *ngIf="!fua.address_line_1.errors?.required && fua.address_line_1.errors?.addressNotValid">PO-box's
                            may
                            not be nominated, as couriers cannot deliver samples to these addresses.</span>
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 new-address-field">
                        <label class="side-panel-sample-lable">Address Line 2</label>
                        <input class="form-control side-panel-sample-field" [ngClass]="{
                              'is-invalid':
                                (frontendUserAddressForm.controls[
                                  'address_line_2'
                                ].invalid &&
                                  frontendUserAddressForm.controls[
                                    'address_line_2'
                                  ].touched) ||
                                submittedMain ||
                                fua.address_line_2.errors?.apiError,
                              'is-valid':
                                !frontendUserAddressForm.controls[
                                  'address_line_2'
                                ].invalid
                            }" formControlName="address_line_2" type="text" placeholder=""/>
                      </div>
                      <div class="col-6 new-address-field">
                        <label class="side-panel-sample-lable">Suburb<span class="required-field">*</span></label>
                        <input class="form-control side-panel-sample-field" [ngClass]="{
                              'is-invalid':
                                (frontendUserAddressForm.controls['suburb']
                                  .invalid &&
                                  frontendUserAddressForm.controls['suburb']
                                    .touched) ||
                                submittedMain ||
                                fua.suburb.errors?.apiError,
                              'is-valid':
                                !frontendUserAddressForm.controls['suburb']
                                  .invalid
                            }" formControlName="suburb" type="text" placeholder="" required/>
                        <span class="invalid-feedback" role="alert" *ngIf="
                              (frontendUserAddressForm.controls['suburb']
                                .invalid &&
                                frontendUserAddressForm.controls['suburb']
                                  .touched) ||
                              submittedMain ||
                              fua.suburb.errors?.apiError
                            ">
                          <span *ngIf="fua.suburb.errors?.required">Please enter suburb</span>
                          <span *ngIf="fua.suburb.errors?.apiError">{{
                            fua.suburb.errors?.apiError
                            }}</span>
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 new-address-field">
                        <label class="side-panel-sample-lable">City<span class="required-field">*</span></label>
                        <input class="form-control side-panel-sample-field" [ngClass]="{
                              'is-invalid':
                                (frontendUserAddressForm.controls['town_city']
                                  .invalid &&
                                  frontendUserAddressForm.controls['town_city']
                                    .touched) ||
                                submittedMain ||
                                fua.town_city.errors?.apiError,
                              'is-valid':
                                !frontendUserAddressForm.controls['town_city']
                                  .invalid
                            }" formControlName="town_city" type="text" placeholder="" required/>
                        <span class="invalid-feedback" role="alert" *ngIf="
                              (frontendUserAddressForm.controls['town_city']
                                .invalid &&
                                frontendUserAddressForm.controls['town_city']
                                  .touched) ||
                              submittedMain ||
                              fua.town_city.errors?.apiError
                            ">
                          <span *ngIf="fua.town_city.errors?.required">Please enter city</span>
                          <span *ngIf="fua.town_city.errors?.apiError">{{
                            fua.town_city.errors?.apiError
                            }}</span>
                        </span>
                      </div>
                      <div class="col-6 new-address-field">
                        <label class="side-panel-sample-lable">State<span class="required-field">*</span></label>
                        <input class="form-control side-panel-sample-field" [ngClass]="{
                              'is-invalid':
                                (frontendUserAddressForm.controls['state_region']
                                  .invalid &&
                                  frontendUserAddressForm.controls['state_region']
                                    .touched) ||
                                submittedMain ||
                                fua.state_region.errors?.apiError,
                              'is-valid':
                                !frontendUserAddressForm.controls['state_region']
                                  .invalid
                            }" formControlName="state_region" type="text" placeholder="" required/>
                        <span class="invalid-feedback" role="alert" *ngIf="
                              (frontendUserAddressForm.controls['state_region']
                                .invalid &&
                                frontendUserAddressForm.controls['state_region']
                                  .touched) ||
                              submittedMain ||
                              fua.state_region.errors?.apiError
                            ">
                          <span *ngIf="fua.state_region.errors?.required">Please enter state</span>
                          <span *ngIf="fua.state_region.errors?.apiError">{{
                            fua.state_region.errors?.apiError
                            }}</span>
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 new-address-field">
                        <label class="side-panel-sample-lable">Country</label>
                        <input class="form-control side-panel-sample-field" [ngClass]="{
                              'is-invalid':
                                (frontendUserAddressForm.controls['country']
                                  .invalid &&
                                  frontendUserAddressForm.controls['country']
                                    .touched) ||
                                submittedMain ||
                                fua.country.errors?.apiError,
                              'is-valid':
                                !frontendUserAddressForm.controls['country']
                                  .invalid
                            }" formControlName="country" type="text" placeholder=""/>
                        <span class="invalid-feedback" role="alert" *ngIf="
                              (frontendUserAddressForm.controls['country']
                                .invalid &&
                                frontendUserAddressForm.controls['country']
                                  .touched) ||
                              submittedMain ||
                              fua.country.errors?.apiError
                            ">
                          <span *ngIf="fua.country.errors?.required">Please enter country</span>
                          <span *ngIf="fua.country.errors?.apiError">{{
                            fua.address_line_1.errors?.apiError
                            }}</span>
                        </span>
                      </div>
                      <div class="col-6 new-address-field">
                        <label class="side-panel-sample-lable">Postal Code<span class="required-field">*</span></label>
                        <input class="form-control side-panel-sample-field" [ngClass]="{
                              'is-invalid':
                                (frontendUserAddressForm.controls['post_code']
                                  .invalid &&
                                  frontendUserAddressForm.controls['post_code']
                                    .touched) ||
                                submittedMain ||
                                fua.post_code.errors?.apiError,
                              'is-valid':
                                !frontendUserAddressForm.controls['post_code']
                                  .invalid
                            }" formControlName="post_code" type="text" placeholder="" required/>
                        <span class="invalid-feedback" role="alert" *ngIf="
                              (frontendUserAddressForm.controls['post_code']
                                .invalid &&
                                frontendUserAddressForm.controls['post_code']
                                  .touched) ||
                              submittedMain ||
                              fua.post_code.errors?.apiError
                            ">
                          <span *ngIf="fua.post_code.errors?.required">Please enter postal code</span>
                          <span *ngIf="fua.post_code.errors?.apiError">{{
                            fua.post_code.errors?.apiError
                            }}</span>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="account-type">
            <h3 class="account-title">Summary</h3>
          </div>
          <div class="added-item-list summary-list">
            <ul>
              <ng-container *ngFor="let sampleProduct of sampleOrders; let in = index">
                <li>
                  <div class="added-item-detail">
                    <div class="item-image">
                      <img src="{{
                            s3BucketImageURL +
                              '/' +
                              sampleProduct?.formatHeroImage?.path +
                              IMAGE_SIZE_69_X_69
                          }}" alt="{{getFileName(sampleProduct?.formatHeroImage?.path)}}"
                           (error)="helperService.getErrorImage($event)"/>
                    </div>

                    <div class="item-other-details">
                      <div class="item-title-price">
                        <div class="item-name">
                          <h3>{{ sampleProduct?.family?.name}} - SAMPLE</h3>
                          <h4>{{ sampleProduct.product_collection_name }}{{ sampleProduct.product_category_name ? ' - ' + sampleProduct.product_category_name : '' }}</h4>
                        </div>
                        <div class="item-price">
                          <span><span>{{ sampleProductPrice | currency }}</span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
          <div class="my-order-total bt-none">
            <span class="order-total-label">Total Value <small>(Ex. GST)</small></span>
            <span class="order-total-amount">
              <span>
                {{ sampleProductPrice| currency }}
              </span>
            </span>
          </div>
          <div class="my-order-total mt-0">
            <span class="order-total-label">Total Charge <small>(Ex. GST)</small></span>
            <span class="order-total-amount">
              <span>
                {{ 0 | currency }}
              </span>
            </span>
          </div>
        </div>
        <div class="pannel-footer">
          <div class="my-order-checkout-btn">
            <button class="btn grey-bg-btn step-2 chechout-order" *ngIf="todayTotalSampleOrders == 0" type="submit" [disabled]="invalidPostalCode">
              Confirm
            </button>
            <button class="btn grey-bg-btn step-2 chechout-order btn-disabled" type="button" [tooltip]="
                  'You can place only 1 sample order per day with 6 items. Your sample order limit has been exceeded.'
                " [adaptivePosition]="true" *ngIf="todayTotalSampleOrders !== 0"  [disabled]="invalidPostalCode">
              Confirm
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="
        !(sampleOrders && sampleOrders != undefined && sampleOrders.length > 0)
      ">
    <div class="address-item-box">
      <p class="no-sample-item">No sample order found</p>
    </div>
  </ng-container>
</div>
