import {ColumnListModel} from './list-column.model';
import {PaginationModel} from './pagination.model';
import { format } from 'date-fns';

export class RequestParamModel {
  pageIndex = 0;
  pageSize?: any;
  sort?: string;
  asc = true;
  fields!: string[];
  expands!: string[];
  totalCount!: number;
  totalPage!: number;
  filter?: string;
  defaultFilter = '';
  setOrArrayFilter = '';
  timer: any;
  sorts?: string;
  group?: any;
  storeRecentProduct?: boolean;
  pageCount?: any;

  constructor() {
    this.pageIndex = 0;
    this.pageSize = 20;
  }

  update<T>(pagedList: PaginationModel<T>) {
    this.pageSize = pagedList.pageSize;
    this.totalCount = pagedList.totalCount;
    this.pageIndex = pagedList.currentPage;
    this.totalPage = pagedList.pageCount;
  }

  setFilters(columns: ColumnListModel[]): any {
    this.filter = '';
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].searchable && columns[i].searchValue) {
        const field = columns[i].searchfield;
        const searcvalue = encodeURI(columns[i].searchValue);
        if (columns[i].datatype === 'date') {
          this.filter += '&filter[' + field + ']' + '=' + format(new Date(columns[i].searchValue), 'yyyy-MM-dd');
      }
        else if (columns[i].datatype !== 'number') {
          this.filter += '&filter[' + field + '][like]' + '=' + searcvalue;
        } else {
          this.filter += '&filter[' + field + ']' + '=' + searcvalue;
        }
      }
    }
  }

  removeFilters(field: any) {
  }

  addDefaultFilter(field: string, value: any, datatype: string) {
    if (field && value) {
      if (datatype !== 'number') {
        this.defaultFilter += '&filter[' + field + ']' + '[like]' + '=' + value;
      } else {
        this.defaultFilter += '&filter[' + field + ']' + '=' + value;
      }
    }
  }


  filterAndWithArray(arr: any, field: any) {
    if (arr !== undefined) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== '') {
          this.setOrArrayFilter += '&assignedto[' + field + '][' + i + ']' + '=' + encodeURIComponent(arr[i]);
        }
      }
    }
  }

  filterInWithArray(arr: any, field: any) {
    if (arr !== undefined) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== '') {
          this.setOrArrayFilter += '&filter[' + field + '][in][]' + '=' + encodeURIComponent(arr[i]);
        }
      }
    }
  }

 filterMinMax(field: any, minValue: any, maxValue?: any) {
    if (field) {
      if (minValue) {
        this.defaultFilter += '&filter[' + field + '][min]' + '=' + minValue;
      }
      if (maxValue) {
        this.defaultFilter += '&filter[' + field + '][max]' + '=' + maxValue;
      }
    }
  }

  filterNotInWithArray(arr: any, field: any) {
    if (arr !== undefined) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== '') {
          this.setOrArrayFilter += '&filter[' + field + '][nin][' + i + ']' + '=' + encodeURIComponent(arr[i]);
        }
      }
    }
  }

  setDefaultFiltersDropdown(field: string, value: any, datatype: string) {
    if (field && value) {
      if (datatype !== 'number') {

        if (datatype === 'date') {
          this.defaultFilter += '&filter[dropdown][' + field + ']' + '=' + format(new Date(value), 'yyyy-MM-dd');
      }

        else {
          this.defaultFilter += '&filter[dropdown][' + field + ']' + '[like]' + '=' + encodeURIComponent(value);
        }
      } else {
        this.defaultFilter += '&filter[dropdown][' + field + ']' + '=' + encodeURIComponent(value);
      }
    }
  }

  addAssignedtoFilter(field: string, value: any) {
    if (field && value) {
      this.defaultFilter += '&assignedto[' + field + ']' + '=' + encodeURIComponent(value);
    } else if (field) {
      this.defaultFilter += '&assignedto[' + field + ']' + '=' + null;
    }
  }

  resetClear(columns: ColumnListModel[]) {
    this.filter = '';
    for (let i = 0; i < columns.length; i++) {
      columns[i].searchValue = '';
    }

  }

  setSorting(columns: ColumnListModel[]): any {
    this.sorts = '';
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sortOrder && columns[i].sortOrder != '') {
        this.sorts += (this.sorts != '' ? ',' : '') + (columns[i].sortOrder === 'desc' ? '-' : '') + columns[i].property
      }
    }
  }

  resetSorting(columns: ColumnListModel[]) {
    this.sorts = '';
    for (let i = 0; i < columns.length; i++) {
      columns[i].sortOrder = '';
    }

  }

  sortChange(field: string, sortType: string): any {
    if (field) {
      this.sorts = (sortType === 'desc' ? '-' : '') + field
    }
  }

  getParams(): string {
    // TODO : Optimize to use the URLQueryParams
    let param = '';

    if (this.defaultFilter) {
      param += this.defaultFilter;
    }

    if (this.filter) {
      param += this.filter;
    }
    const fieldParam = this.getListParam('fields', this.fields);
    if (fieldParam) {
      param += '&' + fieldParam;
    }

    const expandParam = this.getListParam('expand', this.expands);
    if (expandParam) {
      param += '&' + expandParam;
    }

    if (this.sorts) {
      param += '&sort=' + (!this.asc ? '-' : '') + this.sorts;
    }

    if (this.pageIndex) {
      param += '&page=' + this.pageIndex;
    }
    if (this.pageSize) {
      param += '&pageSize=' + this.pageSize;
    }
    if (this.group) {
      param += '&group=true';
    }
    if (this.storeRecentProduct) {
      param += '&storeRecentProduct=' + this.storeRecentProduct;
    }
    if (param) {
      param = param.replace('&', '?');
    }
    if (this.setOrArrayFilter) {
      param += this.setOrArrayFilter;
    }
    return param;
  }

  private getListParam(paramName: string, paramValue: string[]): string {
    let param = '';
    if (paramValue) {
      param = paramName + '=' + paramValue.join(',');
    }
    return param;
  }
}
